/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "@components/Layouts"
import SEO from "@components/seo"
import ArticlePost from "@components/ArticlePost"
import Section from "@components/Section"

export default function Template({ data, ...props }) {

  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const { title, date, image } = frontmatter

  return (
    <Layout>
      <SEO
        title={title}
        image={image}
        pathname={props.location.pathname}
      />
      <Section
        noPb
        className="pink-linear"
        sx={{
          bg: "pink",
          color: "darkPurple",
        }}
      >
        <ArticlePost
          image={image}
          title={title}
          date={date}
          html={html}
          path={props.path}
        />
        <br/>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        image
      }
    }
  }
`
